<template>
  <div class="onboarding-content">
    <base-graphic
      class="body"
      :image="image"
    >
      <template #title>
        {{ title }}
      </template>

      <template #body>
        <slot name="default" />
      </template>
    </base-graphic>
    <base-group
      class="footer"
      align="center"
      spacing="medium"
      direction="vertical"
    >
      <slot name="buttons">
        <base-button
          class="continue"
          type="primary"
          :label="continueButtonLabel"
          size="large"
          @click="$emit('next')"
        />
        <base-button
          v-if="allowBack"
          class="back"
          label="Previous"
          link
          @click="$emit('previous')"
        />
      </slot>
    </base-group>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    allowBack: {
      type: Boolean,
      default: true,
    },
    continueButtonLabel: {
      type: String,
      default: 'Continue',
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100px;
  margin: 0 auto;
  text-align: center;
}
</style>
