<template>
  <base-page
    class="onboarding"
    fullscreen
    :loading="isLoading"
  >
    <template #menu>
      <base-button
        class="button"
        label="Skip All"
        :to="{ name: 'home' }"
        size="small"
        type="ghost"
      />
    </template>
    <template #default>
      <transition-fade>
        <onboarding-content
          v-if="step === 1"
          key="1"
          :title="'Hi ' + user.first_name + '!'"
          :image="require('@/assets/images/graphic/happy.svg')"
          :allow-back="false"
          @next="next"
        >
          <base-paragraph>
            Good to have you here. This is volved, the tool with which you provide valuable
            feedback.
          </base-paragraph>
        </onboarding-content>

        <onboarding-content
          v-else-if="step === 2"
          key="2"
          title="Weekly Pulses"
          :image="require('@/assets/images/graphic/rate.svg')"
          @next="next"
          @previous="previous"
        >
          <base-paragraph>
            Once a week you will receive one question to which we would like to receive your
            response.
          </base-paragraph>
        </onboarding-content>

        <onboarding-content
          v-else-if="step === 3"
          key="3"
          title="100% Anonymous"
          :image="require('@/assets/images/graphic/privacy.svg')"
          @next="next"
          @previous="previous"
        >
          <base-paragraph>
            We always want you give your honest opinion, so your responses are completely anonymous.
          </base-paragraph>
        </onboarding-content>

        <onboarding-content
          v-else-if="step === 4"
          key="4"
          title="Growing Together"
          :image="require('@/assets/images/graphic/data.svg')"
          @next="next"
          @previous="previous"
        >
          <base-paragraph>
            We are completely transparent and you too get insight into the average answers of the
            company.
          </base-paragraph>
        </onboarding-content>

        <onboarding-content
          v-else-if="step === 5"
          key="5"
          title="Let's Start Right Now!"
          :image="require('@/assets/images/graphic/answer.svg')"
          @next="next"
          @previous="previous"
        >
          <base-paragraph>
            To get a first impression, we ask for your opinion on a number of important topics.
          </base-paragraph>
        </onboarding-content>

        <onboarding-content
          v-else-if="step === 6"
          key="6"
          title="Enable notifications"
          :image="require('@/assets/images/graphic/feedback.svg')"
          :nav-buttons="false"
          @next="next"
          @previous="previous"
        >
          <base-paragraph>
            Receive a push notification when we need your opinion.  We will not bother you more than
            one time a week.
          </base-paragraph>

          <template #buttons>
            <button-push-subscribe
              :id="user.uuid"
              @registered="finish"
            />
            <base-button
              label="Not right now"
              link
              @click="finish"
            />
          </template>
        </onboarding-content>
      </transition-fade>
    </template>
  </base-page>
</template>

<script>
/* Import Components */
import OnboardingContent from '@/components/layout/OnboardingContent.vue';
import ButtonPushSubscribe from '@/components/other/ButtonPushSubscribe.vue';

export default {
  components: {
    OnboardingContent,
    ButtonPushSubscribe,
  },
  data() {
    return {
      userId: this.$root.$data.userId,
      step: 1,
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser'];
    },
    isLoading() {
      return !this.user;
    },
  },
  methods: {
    previous() {
      this.step -= 1;
    },
    next() {
      this.step += 1;
    },
    finish() {
      this.$router.push({ name: 'home' });
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding {
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>
