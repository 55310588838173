<template>
  <base-button
    :loading="isLoading"
    size="large"
    @click="onClick"
  >
    Subscribe
  </base-button>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters['push/isLoading'];
    },
    isGranted() {
      return this.$store.getters['push/isGranted'];
    },
    isRegistered() {
      return this.$store.getters['push/isRegistered'];
    },
  },
  watch: {
    isGranted: {
      handler(granted) {
        if (granted) {
          this.$emit('granted');
        }
      },
      immediate: true,
    },
    isRegistered: {
      handler(registered) {
        if (registered) {
          this.$emit('registered');
        }
      },
      immediate: true,
    },
  },
  methods: {
    onClick() {
      if (this.$capacitor.platform === 'web') {
        // Web push not yet supported, just continue
        this.$emit('registered');
      } else {
        // Register for push notifications
        this.$store.dispatch('push/register');
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
